import { render, staticRenderFns } from "./EditTerm.vue?vue&type=template&id=c3f60058&scoped=true&lang=true&"
import script from "./EditTerm.vue?vue&type=script&lang=js&"
export * from "./EditTerm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c3f60058",
  null
  
)

export default component.exports